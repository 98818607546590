<template>
  <div style="height: inherit">
    <div class="email-app-list position-relative space-bottom">
      <div
        class="app-fixed-search d-block d-sm-flex align-items-center"
      >
        <div class="d-flex align-content-left justify-content-between w-100">
          <v-select
            v-model="searchStatus"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="estados"
            :calculate-position="withPopper"
            label="desc"
            item-text="desc"
            item-value="status"
            class="w-100 border-0"
            :placeholder="$t('Filtrar por estado')"
            :clearable="false"
            @input="refreshLoadDataMap"
          >
            <template #option="{ desc, url }">
              <img
                :src="url"
                style="height:25px; margin: -10px 5px 0 0;"
              > {{ desc }}
            </template>

            <template #selected-option="{ desc, url }">
              <img
                :src="url"
                style="height:25px; margin: -10px 5px 0 -5px;"
              > {{ desc }}
            </template>

            <div slot="no-options">
              {{ $t('Sem resultados') }}
            </div>
          </v-select>
        </div>
        <div class="d-flex align-content-left justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="searchTxt"
              value=""
              :placeholder="$t('Pesquisar por local')"
              @input="refreshLoadDataMap"
            />
          </b-input-group>
        </div>
      </div>

      <b-row>
        <b-col cols="12">
          <GmapMap
            ref="googleMapRef"
            :center="mapsLatLong"
            map-type-id="roadmap"
            :zoom="zoomMap"
            class="vue-google-maps mobile-with-two-rows"
            :options="{
              keyboardShortcuts: false,
              gestureHandling:'cooperative',
              rotateControl: false,
              tilt:0,
            }"
            @idle="savePositionMap"
          />
        </b-col>
      </b-row>
    </div>

    <b-overlay
      :show="mapProcessed"
      no-wrap
    />

    <info
      v-if="showInfoStatus===true"
      :estados="estados"
    />

  </div>

</template>

<script>
import store from '@/store'
import '@/libs/sweet-alerts'
import { onUnmounted } from '@vue/composition-api'
import {
  BFormInput, BInputGroup, BInputGroupPrepend, BRow, BCol, BOverlay,
} from 'bootstrap-vue'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import listingsModule from '@store-modules/listings/map/index'
import { mapGetters } from 'vuex'
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import MarkerClusterer from '@googlemaps/markerclustererplus'
import { showMsgRequest, eventsCustomSelects } from '@core-custom/mixins/geral'
import { gmapApi as googleMapsApi } from 'vue2-google-maps'
import vSelect from 'vue-select'
import Info from './info.vue'

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_API_GOOGLE_MAPS,
    region: store.getters['auth/countryApp'],
    language: store.getters['auth/countryApp'],
  },
  installComponents: false,
})

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BRow,
    BCol,
    BOverlay,
    GmapMap: VueGoogleMaps.Map,
    Info,
    vSelect,
  },
  mixins: [showMsgRequest, eventsCustomSelects],
  data() {
    return {
      geocoder: null,
      bLoadPreferenceUser: false,
      searchTxt: '',
      clusterer: null,
      markers: [],
      map: null,
      currentInfoWindow: null,
      saveFilterQuery: '',
      listings: [],
      mapProcessed: true,
      showInfoStatus: false,
      searchStatus: null,
    }
  },
  computed: {
    ...mapGetters('listings', ['estados', 'coordinates', 'clusterStyle', 'zoomMap']),
    mapsLatLong() {
      try {
        if (this.coordinates !== '') {
          const [sLat, sLong] = this.coordinates.split('###')

          return {
            lat: Number(sLat),
            lng: Number(sLong),
          }
        }

        throw new Error('')
      } catch (err) {
        return {
          lat: Number(0),
          lng: Number(0),
        }
      }
    },
    google: googleMapsApi,
    mapReady() {
      return this.google && this.map !== null
    },
  },
  watch: {
    listings: {
      handler(newVal) {
        this.plotMarkers(newVal)
      },
      immediate: true,
    },
  },
  mounted() {
    try {
      this.$refs.googleMapRef.$mapPromise.then(map => {
        this.map = map
      })

      this.$gmapApiPromiseLazy().then(res => {
        this.geocoder = new res.maps.Geocoder()

        if ((localStorage.getItem(this.$route.name) !== null) && (localStorage.getItem(this.$route.name) !== '')) {
          try {
            const oLoadMap = JSON.parse(localStorage.getItem(this.$route.name))

            if (
              (oLoadMap !== null)
            && (oLoadMap !== undefined)
            && (oLoadMap !== '')
            && ('status' in oLoadMap)
            ) {
              this.searchStatus = this.estados.find(o => o.status === oLoadMap.status.status)
            }

            if (
              (oLoadMap !== null)
            && (oLoadMap !== undefined)
            && (oLoadMap !== '')
            && ('map_coord_lat' in oLoadMap)
            && ('map_coord_long' in oLoadMap)
            && ('map_zoom' in oLoadMap)
            && (oLoadMap.map_coord_lat !== '')
            && (oLoadMap.map_coord_long !== '')
            && (oLoadMap.map_zoom !== '')
            ) {
              this.$store.dispatch('listings/setMapLatLong', {
                lat: oLoadMap.map_coord_lat,
                lng: oLoadMap.map_coord_long,
                listview: true,
                status: this.searchStatus.status || '',
              }).then(resListings => {
                this.listings = resListings.listings
                this.saveFilterQuery = resListings.query || ''
              })
              this.$store.commit('listings/setZoomMap', oLoadMap.map_zoom)
              this.searchTxt = oLoadMap.searchTxt
              this.bLoadPreferenceUser = true
            }
          } catch (err) {
            localStorage.removeItem(this.$route.name)
          }
        }

        if (this.bLoadPreferenceUser === false) {
          if ((this.searchStatus === null) || (this.searchStatus === undefined)) {
            this.searchStatus = this.estados.find(o => o.status === 1)
          }

          this.geocoder.geocode({ address: this.getAddressDefault() }, (results, status) => {
            if (status === 'OK') {
              const latitude = results[0].geometry.location.lat() || Number(0)
              const longitude = results[0].geometry.location.lng() || Number(0)
              this.$store.dispatch('listings/setMapLatLong', {
                lat: latitude,
                lng: longitude,
                listview: true,
                status: this.searchStatus.status || '',
              }).then(resListings => {
                this.listings = resListings.listings
                this.saveFilterQuery = resListings.query || ''
              })
            }
          })
        }
      })
    } catch (err) {
      //
    }
  },
  methods: {
    getAddressDefault() {
      let address = ''

      if (this.$store.getters['auth/countryApp'] === 'pt') {
        address = 'Portugal'
      }

      if (this.$store.getters['auth/countryApp'] === 'es') {
        address = 'Espanha'
      }

      return address
    },
    refreshLoadDataMap() {
      let sAddressSearch = this.getAddressDefault()
      let sAddressZoom = 6

      if (this.searchTxt !== '') {
        sAddressSearch = this.searchTxt
        sAddressZoom = 12
      }

      this.geocoder.geocode({ address: sAddressSearch }, (results, status) => {
        if (status === 'OK') {
          this.mapProcessed = true
          const latitude = results[0].geometry.location.lat() || Number(0)
          const longitude = results[0].geometry.location.lng() || Number(0)
          this.$store.dispatch('listings/setMapLatLong', {
            lat: latitude,
            lng: longitude,
            listview: true,
            status: this.searchStatus.status || '',
          }).then(resListings => {
            this.listings = resListings.listings
            this.saveFilterQuery = resListings.query || ''
          })
          this.$store.commit('listings/setZoomMap', sAddressZoom)
        }

        this.savePositionMap()
      })
    },
    async savePositionMap() {
      const map = await this.$refs.googleMapRef.$mapObject
      const oMap = {
        map_zoom: map.zoom,
        map_coord_lat: map.center.lat(),
        map_coord_long: map.center.lng(),
        searchTxt: this.searchTxt,
        status: this.searchStatus,
      }

      localStorage.setItem(this.$route.name, JSON.stringify(oMap))
    },
    plotMarkers(listings) {
      if (!listings.length) {
        if (this.mapProcessed === true) {
          if (this.clusterer) {
            this.clusterer.clearMarkers()
          }
        }

        this.mapProcessed = false
        return
      }

      if (!this.clusterer) {
        this.clusterer = new MarkerClusterer(this.map, [], {
          styles: this.clusterStyle.styles,
          maxZoom: this.clusterStyle.maxZoom,
          minimumClusterSize: this.clusterStyle.minimumClusterSize,
          zoomOnClick: this.clusterStyle.zoomOnClick,
        })
      } else {
        this.clusterer.clearMarkers()
      }

      const chunkArray = (myArray, chunkSize) => {
        const results = new Set([])

        while (myArray.length) {
          results.add(myArray.splice(0, chunkSize))
        }

        return results
      }

      const txtTitle = this.$t('Angariações')

      const result = chunkArray(listings, 100)

      if (result.size > 0) {
        const chunckListing = Array.from(result.values())

        for (let index = 0; index < chunckListing.length; index += 1) {
          this.markers = new Set([])

          for (let indexListing = 0; indexListing < chunckListing[index].length; indexListing += 1) {
            const row = chunckListing[index][indexListing]
            const marker = new this.google.maps.Marker({
              position: {
                lat: parseFloat(row.latitude),
                lng: parseFloat(row.longitude),
              },
              icon: {
                url: row.icon,
                scaledSize: new this.google.maps.Size(18, 18),
              },
              shadow: row.shadow,
              title: row.sw012s02,
              idListing: row.sw012s01,
            })

            // eslint-disable-next-line no-loop-func
            marker.addListener('click', async () => {
              if (marker.idListing !== '') {
                // Verifica se a coordenada atual tem mais alguma angariacao no local
                await this.$store.dispatch('listings/mapsCheckMarkerInlocation', {
                  latLong: `${marker.position.lat()}###${marker.position.lng()}`,
                  query: this.saveFilterQuery || '',
                }).then(resultCheck => {
                  if (resultCheck.length > 0) {
                    let txtLink = `<h6>${txtTitle}</h6>`
                    resultCheck.forEach(item => {
                      txtLink += `<p class="m-0"><a href="javascript:window.oVue.$router.push('/listings/viewDetail/${item.id}')">${item.desc}</a></p>`
                    })

                    const infoWindow = new this.google.maps.InfoWindow({
                      content: txtLink,
                    })

                    if (this.currentInfoWindow === infoWindow) {
                      return
                    }
                    if (!this.isNullOrUndefined(this.currentInfoWindow)) {
                      this.currentInfoWindow.close()
                    }
                    infoWindow.open(this.nativeMap, marker)
                    this.currentInfoWindow = infoWindow
                  }
                })
              }
            })

            this.markers.add(marker)
          }

          this.clusterer.addMarkers(Array.from(this.markers), false)
        }

        this.mapProcessed = false
      }
    },
    async sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    isNullOrUndefined(obj) {
      return typeof obj === 'undefined' || obj === null
    },
    viewDetailListing(id) {
      this.$router.push({ name: 'listings-view-detail', params: { id } })
    },
  },
  setup() {
    const LISTINGS_MODULE_NAME = 'listings'

    if (!store.hasModule(LISTINGS_MODULE_NAME)) {
      store.registerModule(LISTINGS_MODULE_NAME, listingsModule)

      onUnmounted(() => {
        if (store.hasModule(LISTINGS_MODULE_NAME)) store.unregisterModule(LISTINGS_MODULE_NAME)
      })
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
