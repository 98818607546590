import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    estados: state => state.estados.value,
    clusterStyle: state => state.clusterStyle.value,
    coordinates: state => state.coordinates.value,
    zoomMap: state => state.zoomMap.value,
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setMapLatLong(state, payload) {
      try {
        if (payload.lat && payload.lng) {
          state.coordinates.value = `${payload.lat}###${payload.lng}`
        } else {
          state.coordinates.value = ''
        }
      } catch (err) {
        state.coordinates.value = ''
      }
    },
    setZoomMap(state, payload) {
      state.zoomMap.value = payload
    },
  },
  actions: {
    loadListingsMaps({ dispatch }, payload) {
      const listviewFilterParams = new FormData()
      listviewFilterParams.append('fsw012s64', payload.status || '')
      listviewFilterParams.append('jsonGoogleMaps', 1)
      listviewFilterParams.append('fullViewMaps', 1)

      return new Promise((resolve, reject) => {
        dispatch('request/cancelTokensPendingByUrl', 'listings/loadListingsFullMaps', { root: true })

        api.post(`${apiConfig.url_base_api}listings/loadListingsFullMaps`, listviewFilterParams)
          .then(response => {
            resolve({
              listings: response.data.angariacoes,
              query: response.data.sSaveFilter,
            })
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter as angariações')))
            }
          })
      })
    },

    mapsCheckMarkerInlocation({ dispatch }, payload) {
      const listviewFilterParams = new FormData()
      listviewFilterParams.append('latLngAng', payload.latLong || '')
      listviewFilterParams.append('filterMaps', payload.query || '')

      return new Promise((resolve, reject) => {
        dispatch('request/cancelTokensPendingByUrl', 'listings/mapsCheckMarkerInlocation', { root: true })

        api.post(`${apiConfig.url_base_api}listings/mapsCheckMarkerInlocation`, listviewFilterParams)
          .then(response => {
            resolve(response.data.angariacoes)
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter as angariações')))
            }
          })
      })
    },

    setMapLatLong({ commit, dispatch }, payload) {
      commit('setMapLatLong', payload)

      if (payload.listview === true) {
        return dispatch('loadListingsMaps', payload)
      }

      return []
    },
  },
}
