<template>
  <div
    class="customizer d-none d-md-block"
    :class="{'open': isCustomizerOpen}"
  >
    <b-link
      class="customizer-toggle d-flex align-items-center justify-content-center"
      @click="isCustomizerOpen = !isCustomizerOpen"
    >
      <feather-icon
        icon="TriangleIcon"
        size="20"
        class="spinner"
      />
    </b-link>

    <!-- Header -->
    <div class="customizer-section d-flex justify-content-between align-items-center">
      <div>
        <h4 class="text-uppercase mb-0">
          {{ $t('Legenda mapa') }}
        </h4>
        <small>{{ $t('Estados da angariação') }}</small>
      </div>
      <feather-icon
        icon="XIcon"
        size="18"
        class="cursor-pointer"
        @click="isCustomizerOpen = !isCustomizerOpen"
      />
    </div>
    <!-- Header -->

    <vue-perfect-scrollbar
      class="ps-customizer-area scroll-area"
      :settings="configSettingsScroll"
    >
      <b-list-group>
        <b-list-group-item
          v-for="(estado, key) in estados"
          :key="`img-info-map-${key}`"
          class="d-flex border-0"
        >
          <div class="mr-1">
            <b-img
              :src="estado.url"
            />
          </div>
          <div class="pt-1">
            <b>{{ estado.desc }}</b>
          </div>
        </b-list-group-item>
      </b-list-group>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import {
  BLink, BListGroup, BListGroupItem, BImg,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { configSettingsScroll } from '@core-custom/utils/ui'

export default {
  components: {
    BLink,
    BImg,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
  },
  props: {
    estados: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  setup() {
    const isCustomizerOpen = ref(false)

    return {
      isCustomizerOpen,
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss" scoped>
.customizer-section {
  padding: 1.5rem;
    border-bottom: 1px solid #ebe9f1;

  .dark-layout & {
    border-color: $theme-dark-border-color;
  }
}

.ps-custoizer-area {
  height: calc(100% - 83px)
}
</style>
