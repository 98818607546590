import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'

const defaultState = () => ({
  coordinates: ref(''),
  zoomMap: ref(6),
  estados: ref([
    {
      status: 1,
      url: `${process.env.VUE_APP_URL_ASSETS_API}images/googleMaps/marketAtivo.png`,
      desc: i18n.t('Ativo'),
    },
    {
      status: 2,
      url: `${process.env.VUE_APP_URL_ASSETS_API}images/googleMaps/marketReservado.png`,
      desc: i18n.t('Reservado'),
    },
    {
      status: 3,
      url: `${process.env.VUE_APP_URL_ASSETS_API}images/googleMaps/marketVendido.png`,
      desc: i18n.t('Vendido'),
    },
    {
      status: 4,
      url: `${process.env.VUE_APP_URL_ASSETS_API}images/googleMaps/marketCancelado.png`,
      desc: i18n.t('Cancelado'),
    },
    {
      status: 7,
      url: `${process.env.VUE_APP_URL_ASSETS_API}images/googleMaps/marketArrendado.png`,
      desc: i18n.t('Arrendado'),
    },
    {
      status: 5,
      url: `${process.env.VUE_APP_URL_ASSETS_API}images/googleMaps/marketPendente.png`,
      desc: i18n.t('Pendente'),
    },
    {
      status: 8,
      url: `${process.env.VUE_APP_URL_ASSETS_API}images/googleMaps/marketSuspenso.png`,
      desc: i18n.t('Suspenso'),
    },
  ]),
  clusterStyle: ref({
    styles: [{
      height: 53,
      url: `${process.env.VUE_APP_URL_ASSETS_API}images/googleMaps/marker_nivel1.png`,
      width: 53,
      textColor: 'white',
      textSize: 12,
      textLineHeight: 53,
    },
    {
      height: 56,
      url: `${process.env.VUE_APP_URL_ASSETS_API}images/googleMaps/marker_nivel2.png`,
      width: 56,
      textColor: 'white',
      textSize: 12,
      textLineHeight: 56,
    },
    {
      height: 66,
      url: `${process.env.VUE_APP_URL_ASSETS_API}images/googleMaps/marker_nivel3.png`,
      width: 66,
      textColor: 'white',
      textSize: 12,
      textLineHeight: 66,
    },
    {
      height: 78,
      url: `${process.env.VUE_APP_URL_ASSETS_API}images/googleMaps/marker_nivel4.png`,
      width: 78,
      textColor: 'white',
      textSize: 12,
      textLineHeight: 78,
    },
    {
      height: 90,
      url: `${process.env.VUE_APP_URL_ASSETS_API}images/googleMaps/marker_nivel5.png`,
      width: 90,
      textColor: 'white',
      textSize: 12,
      textLineHeight: 90,
    }],
    minimumClusterSize: 5,
    zoomOnClick: false,
    maxZoom: 15,
  }),
})

export default defaultState
